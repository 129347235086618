

















































































































































































































































































































































































































































































































import { Component } from 'vue-property-decorator';
import { getComponent, getConfigEnv } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import { v4 as uuidv4 } from 'uuid';
import assetsModule from '@/store/modules/assetsModule';
import proceduresModule from '@/store/modules/proceduresModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    UpdateProcedure: () => getComponent('procedures/UpdateProcedure'),
    SaveStep: () => getComponent('procedures/SaveStep'),
  },
})
export default class UpdateProcedure extends mixins(DataLoading) {
  procedureStep = 1;

  showName = true;

  showStepsComponent = false;

  isLoadingProceduresData = false

  procedure: any = {
    name: 'Clean out',
    steps: [],
    history: [],
    type: '',
    eventType: 'Workover',
    eventWellName: '',
    estimateCost: '',
    estimateDuration: '',
    selectedComponents: [],
    selectedVendors: [],
  }

  componentInput = '';

  showComponentModal = false;

  vendorName = '';

  vendorEmail = '';

  vendorFirstname = '';

  vendorLastname = '';

  vendorPhone = '';

  showVendorModal = false;

  activeStep: any = null;

  componentsArray: any[] = []

  vendorsArray: any[] = []


  didAddProcedureStep(steps) {
	  this.showStepsComponent = false
	  this.procedure.steps = steps
  }

  get procedureTypes() {
    return [
      {
        id: 0,
        name: 'General procedure',
        value: 'Generic',
        hint: `Procedure templates allow you to create generic procedures for specific failure types.
            These procedures may be copied and modified to fit well or job specific procedures.`,
      },
      {
        id: 1,
        value: 'WellSpecific',
        name: 'Procedure for specific well or upcoming job',
        hint: `Create a procedure for a specific well or upcoming job. Provide more specific details
            for an upcoming job or an individual well.`,
      },
    ];
  }

  get showActionBtns() {
    return (this.procedureStep !== 3) || (this.procedure.steps.length && !this.showStepsComponent);
  }

  get cancelText() {
    return this.procedureStep === 1 ? 'Cancel' : 'Discard';
  }

  get saveText() {
    return this.procedureStep === 1 ? 'Save & Continue' : 'Save procedure';
  }

  get saveBtnDisabled() {
    if (this.procedureStep === 2 && this.procedure.type === 'WellSpecific') {
      return !this.procedure.estimateCost
        || !this.procedure.estimateDuration
        || !this.procedure.eventWellName
        || !this.procedure.name;
    }
    return this.procedureStep === 1 ? !this.procedure.type : !this.procedure.name;
  }

  get failures() {
    return getConfigEnv('PROCEDURE_FAILURES');
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get specificFailures() {
    return [
      'Clean out',
      'Hole in tubing',
      'Replace damaged component',
      'Changed out artificial lift',
    ];
  }

  get components() {
	var selectedComponentsArray: any = []
	for (var x = 0; x < this.componentsArray.length; x++) {
		selectedComponentsArray.push(this.componentsArray[x].name)
	}
	return selectedComponentsArray
  }

  get vendors() {
	var selectedVendorsArray: any = []
	for (var x = 0; x < this.vendorsArray.length; x++) {
		selectedVendorsArray.push(this.vendorsArray[x].name)
	}
	return selectedVendorsArray
  }



  async created() {
    if (!this.enabledWells.length) {
		this.isLoadingProceduresData = true
      await assetsModule.getEnabledWells();
	  this.isLoadingProceduresData = false
	}
    // await this.$store.dispatch(SET_COMPONENTS);
    // await this.$store.dispatch(SET_VENDORS);
    if (this.$route.params.procedure) {
      // this.procedure = {
      //   ...this.procedure,
      //   ...this.$route.params.procedure,
      // };
    }
    // if (this.$route.params.step) {
    //   this.procedureStep = this.$route.params.step;
    // }
    this.stopDataLoading();
  }

  async saveCurrentStep() {
    if (this.procedureStep === 3) {
      this.startDataLoading();
      if (this.procedure?.id) {
        // await this.$store.dispatch(EDIT_PROCEDURE_ITEM, this.procedure);
      } else {

		await proceduresModule.editActiveProcedure(this.procedure)
		// tasqFeedbackModule.leaveFeedback({
		// 	tasq: this.tasq,
		// 	selection: this.selectionAction,
		// });

		// tasqFeedbackModule.leaveFeedback({
		// 	tasq: this.tasq,
		// 	selection: this.selectionAction,
		// });
        // await this.$store.dispatch(ADD_PROCEDURE_ITEM, this.procedure);



      }
	  this.$emit('reload-page')
      await this.$router.push({ name: 'Workflow' });
      this.stopDataLoading();
      return;
    } else if (this.procedureStep === 2 && this.procedure.type === 'WellSpecific') {

	}
    this.procedureStep += 1;
  }

  toggleSaveStepsComponent(id = '', addStep = false) {
    this.showStepsComponent = true;

    if (addStep) {
      id = uuidv4();
      const stepsLength = this.procedure.steps.length;
      this.procedure.steps.push({
        id,
        order: stepsLength + 1,
        title: '',
        text: '',
        responses: [ 'Not applicable' ],
        exclusions: [],
        showActions: false,
        showDetails: false,
      });
    }

    this.activeStep = this.procedure.steps.find(i => i.id === id);
  }

  resetStepState({ object }) {
    if (object) {
      Object.assign(object, { showActions: false });
    }
  }

  editStep(step) {
    this.toggleSaveStepsComponent(step.id);
  }



  onComponentModalClose({ isOk }) {
    if (this.componentInput && isOk) {
      const component = {
        id: uuidv4(),
        name: this.componentInput,
        noBackendDelete: true,
      };
	  this.componentsArray.push(component)
      // this.$store.dispatch(ADD_COMPONENT, {
      //   component,
      // });
      this.showComponentModal = false;
      this.componentInput = '';
      this.procedure.selectedComponents.push(component.id);

    }
  }

  onVendorModalClose({ isOk }) {
    //TODO ADD validations
    if (isOk) {
      const vendor = {
        id: uuidv4(),
        name: this.vendorName,
		email: this.vendorEmail,
		firstname: this.vendorFirstname,
		lastname: this.vendorLastname,
		phone: this.vendorPhone,
		noBackendDelete: true,
      };
	  this.vendorsArray.push(vendor)
      // await this.$store.dispatch(ADD_VENDOR, {
      //   vendor: {
      //     id: uuidv4(),
      //     name: this.vendorName,
      //     email: this.vendorEmail,
      //     firstname: this.vendorFirstname,
      //     lastname: this.vendorLastname,
      //     phone: this.vendorPhone,
      //     noBackendDelete: true,
      //   },
      // });
	  this.showVendorModal = false;
      this.vendorName = '';
      this.vendorEmail = '';
      this.vendorFirstname = '';
      this.vendorLastname = '';
      this.vendorPhone = '';
	  this.procedure.selectedVendors.push(vendor.id);
    }
  }
}
//     removeComponent(component) {
//       const findSelectedIndex = this.procedure.selectedComponents.indexOf(component.id);
//       if (findSelectedIndex !== -1) {
//         this.procedure.selectedComponents.splice(findSelectedIndex, 1);
//       }
//       this.$store.dispatch(REMOVE_COMPONENT, component);
//     },
//     removeVendor(vendor) {
//       const findSelectedIndex = this.procedure.selectedVendors.indexOf(vendor.id);
//
//       if (this.procedure.selectedVendor === vendor.id) {
//         this.procedure.selectedVendors.splice(findSelectedIndex, 1);
//       }
//       this.$store.dispatch(REMOVE_VENDOR, { vendor });
//     },
//   },
